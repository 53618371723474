/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
	/** primary **/
	--ion-color-primary: #ff7e20;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #d87f3f;
	--ion-color-primary-tint: #f8a569;

	/** secondary **/
	--ion-color-secondary: #3dc2ff;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	/** tertiary **/
	--ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	/** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
}

/** Color palette for strokes **/

:root {
	--ion-color-pen-pink: #ffc0cb;
	--ion-color-pen-pink-rgb: 255, 192, 203;
	--ion-color-pen-pink-contrast: #000000;
	--ion-color-pen-pink-contrast-rgb: 0, 0, 0;
	--ion-color-pen-pink-shade: #e0a9b3;
	--ion-color-pen-pink-tint: #ffc6d0;
}

.ion-color-pen-pink {
	--ion-color-base: var(--ion-color-pen-pink);
	--ion-color-base-rgb: var(--ion-color-pen-pink-rgb);
	--ion-color-contrast: var(--ion-color-pen-pink-contrast);
	--ion-color-contrast-rgb: var(--ion-color-pen-pink-contrast-rgb);
	--ion-color-shade: var(--ion-color-pen-pink-shade);
	--ion-color-tint: var(--ion-color-pen-pink-tint);
}

:root {
	--ion-color-pen-black: #000000;
	--ion-color-pen-black-rgb: 0, 0, 0;
	--ion-color-pen-black-contrast: #ffffff;
	--ion-color-pen-black-contrast-rgb: 255, 255, 255;
	--ion-color-pen-black-shade: #000000;
	--ion-color-pen-black-tint: #1a1a1a;
}

.ion-color-pen-black {
	--ion-color-base: var(--ion-color-pen-black);
	--ion-color-base-rgb: var(--ion-color-pen-black-rgb);
	--ion-color-contrast: var(--ion-color-pen-black-contrast);
	--ion-color-contrast-rgb: var(--ion-color-pen-black-contrast-rgb);
	--ion-color-shade: var(--ion-color-pen-black-shade);
	--ion-color-tint: var(--ion-color-pen-black-tint);
}

:root {
	--ion-color-pen-purple: #800080;
	--ion-color-pen-purple-rgb: 128, 0, 128;
	--ion-color-pen-purple-contrast: #ffffff;
	--ion-color-pen-purple-contrast-rgb: 255, 255, 255;
	--ion-color-pen-purple-shade: #710071;
	--ion-color-pen-purple-tint: #8d1a8d;
}

.ion-color-pen-purple {
	--ion-color-base: var(--ion-color-pen-purple);
	--ion-color-base-rgb: var(--ion-color-pen-purple-rgb);
	--ion-color-contrast: var(--ion-color-pen-purple-contrast);
	--ion-color-contrast-rgb: var(--ion-color-pen-purple-contrast-rgb);
	--ion-color-shade: var(--ion-color-pen-purple-shade);
	--ion-color-tint: var(--ion-color-pen-purple-tint);
}

:root {
	--ion-color-pen-red: #ff0000;
	--ion-color-pen-red-rgb: 255, 0, 0;
	--ion-color-pen-red-contrast: #ffffff;
	--ion-color-pen-red-contrast-rgb: 255, 255, 255;
	--ion-color-pen-red-shade: #e00000;
	--ion-color-pen-red-tint: #ff1a1a;
}

.ion-color-pen-red {
	--ion-color-base: var(--ion-color-pen-red);
	--ion-color-base-rgb: var(--ion-color-pen-red-rgb);
	--ion-color-contrast: var(--ion-color-pen-red-contrast);
	--ion-color-contrast-rgb: var(--ion-color-pen-red-contrast-rgb);
	--ion-color-shade: var(--ion-color-pen-red-shade);
	--ion-color-tint: var(--ion-color-pen-red-tint);
}

:root {
	--ion-color-pen-orange: #ffa500;
	--ion-color-pen-orange-rgb: 255, 165, 0;
	--ion-color-pen-orange-contrast: #000000;
	--ion-color-pen-orange-contrast-rgb: 0, 0, 0;
	--ion-color-pen-orange-shade: #e09100;
	--ion-color-pen-orange-tint: #ffae1a;
}

.ion-color-pen-orange {
	--ion-color-base: var(--ion-color-pen-orange);
	--ion-color-base-rgb: var(--ion-color-pen-orange-rgb);
	--ion-color-contrast: var(--ion-color-pen-orange-contrast);
	--ion-color-contrast-rgb: var(--ion-color-pen-orange-contrast-rgb);
	--ion-color-shade: var(--ion-color-pen-orange-shade);
	--ion-color-tint: var(--ion-color-pen-orange-tint);
}

:root {
	--ion-color-pen-yellow: #ffff00;
	--ion-color-pen-yellow-rgb: 255, 255, 0;
	--ion-color-pen-yellow-contrast: #000000;
	--ion-color-pen-yellow-contrast-rgb: 0, 0, 0;
	--ion-color-pen-yellow-shade: #e0e000;
	--ion-color-pen-yellow-tint: #ffff1a;
}

.ion-color-pen-yellow {
	--ion-color-base: var(--ion-color-pen-yellow);
	--ion-color-base-rgb: var(--ion-color-pen-yellow-rgb);
	--ion-color-contrast: var(--ion-color-pen-yellow-contrast);
	--ion-color-contrast-rgb: var(--ion-color-pen-yellow-contrast-rgb);
	--ion-color-shade: var(--ion-color-pen-yellow-shade);
	--ion-color-tint: var(--ion-color-pen-yellow-tint);
}

:root {
	--ion-color-pen-white: #ffffff;
	--ion-color-pen-white-rgb: 255, 255, 255;
	--ion-color-pen-white-contrast: #000000;
	--ion-color-pen-white-contrast-rgb: 0, 0, 0;
	--ion-color-pen-white-shade: #e0e0e0;
	--ion-color-pen-white-tint: #ffffff;
}

.ion-color-pen-white {
	--ion-color-base: var(--ion-color-pen-white);
	--ion-color-base-rgb: var(--ion-color-pen-white-rgb);
	--ion-color-contrast: var(--ion-color-pen-white-contrast);
	--ion-color-contrast-rgb: var(--ion-color-pen-white-contrast-rgb);
	--ion-color-shade: var(--ion-color-pen-white-shade);
	--ion-color-tint: var(--ion-color-pen-white-tint);
}

:root {
	--ion-color-pen-green: #7cfc00;
	--ion-color-pen-green-rgb: 124, 252, 0;
	--ion-color-pen-green-contrast: #000000;
	--ion-color-pen-green-contrast-rgb: 0, 0, 0;
	--ion-color-pen-green-shade: #6dde00;
	--ion-color-pen-green-tint: #89fc1a;
}

.ion-color-pen-green {
	--ion-color-base: var(--ion-color-pen-green);
	--ion-color-base-rgb: var(--ion-color-pen-green-rgb);
	--ion-color-contrast: var(--ion-color-pen-green-contrast);
	--ion-color-contrast-rgb: var(--ion-color-pen-green-contrast-rgb);
	--ion-color-shade: var(--ion-color-pen-green-shade);
	--ion-color-tint: var(--ion-color-pen-green-tint);
}

:root {
	--ion-color-pen-darkgreen: #008000;
	--ion-color-pen-darkgreen-rgb: 0, 128, 0;
	--ion-color-pen-darkgreen-contrast: #ffffff;
	--ion-color-pen-darkgreen-contrast-rgb: 255, 255, 255;
	--ion-color-pen-darkgreen-shade: #007100;
	--ion-color-pen-darkgreen-tint: #1a8d1a;
}

.ion-color-pen-darkgreen {
	--ion-color-base: var(--ion-color-pen-darkgreen);
	--ion-color-base-rgb: var(--ion-color-pen-darkgreen-rgb);
	--ion-color-contrast: var(--ion-color-pen-darkgreen-contrast);
	--ion-color-contrast-rgb: var(--ion-color-pen-darkgreen-contrast-rgb);
	--ion-color-shade: var(--ion-color-pen-darkgreen-shade);
	--ion-color-tint: var(--ion-color-pen-darkgreen-tint);
}

:root {
	--ion-color-pen-blue: #00bfff;
	--ion-color-pen-blue-rgb: 0, 191, 255;
	--ion-color-pen-blue-contrast: #000000;
	--ion-color-pen-blue-contrast-rgb: 0, 0, 0;
	--ion-color-pen-blue-shade: #00a8e0;
	--ion-color-pen-blue-tint: #1ac5ff;
}

.ion-color-pen-blue {
	--ion-color-base: var(--ion-color-pen-blue);
	--ion-color-base-rgb: var(--ion-color-pen-blue-rgb);
	--ion-color-contrast: var(--ion-color-pen-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-pen-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-pen-blue-shade);
	--ion-color-tint: var(--ion-color-pen-blue-tint);
}

:root {
	--ion-color-pen-darkblue: #0000ff;
	--ion-color-pen-darkblue-rgb: 0, 0, 255;
	--ion-color-pen-darkblue-contrast: #ffffff;
	--ion-color-pen-darkblue-contrast-rgb: 255, 255, 255;
	--ion-color-pen-darkblue-shade: #0000e0;
	--ion-color-pen-darkblue-tint: #1a1aff;
}

.ion-color-pen-darkblue {
	--ion-color-base: var(--ion-color-pen-darkblue);
	--ion-color-base-rgb: var(--ion-color-pen-darkblue-rgb);
	--ion-color-contrast: var(--ion-color-pen-darkblue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-pen-darkblue-contrast-rgb);
	--ion-color-shade: var(--ion-color-pen-darkblue-shade);
	--ion-color-tint: var(--ion-color-pen-darkblue-tint);
}

:root {
	--ion-color-pen-brown: #d2691e;
	--ion-color-pen-brown-rgb: 210, 105, 30;
	--ion-color-pen-brown-contrast: #ffffff;
	--ion-color-pen-brown-contrast-rgb: 255, 255, 255;
	--ion-color-pen-brown-shade: #b95c1a;
	--ion-color-pen-brown-tint: #d77835;
}

.ion-color-pen-brown {
	--ion-color-base: var(--ion-color-pen-brown);
	--ion-color-base-rgb: var(--ion-color-pen-brown-rgb);
	--ion-color-contrast: var(--ion-color-pen-brown-contrast);
	--ion-color-contrast-rgb: var(--ion-color-pen-brown-contrast-rgb);
	--ion-color-shade: var(--ion-color-pen-brown-shade);
	--ion-color-tint: var(--ion-color-pen-brown-tint);
}

.ion-color-button-popover {
	--offset-y: -150px;
}

.ion-size-button-popover {
	--offset-x: -50px;
	--offset-y: -50px;
}

.ion-tools-button-popover {
	--offset-y: -50px;
}

@font-face {
	font-family: 'Roboto-Regular';
	src: url('../assets/fonts/Roboto-Regular.ttf');
}

@font-face {
	font-family: 'Raleway-Regular';
	src: url('../assets/fonts/Raleway-Regular.ttf');
}

:root {
	/* Set font family entire app */
	--ion-font-family: Raleway-Regular, sans-serif;
}
